<template>
  <div
    class="navbar-container main-menu-content"
  >
    <horizontal-nav-menu-items :items="$store.state.menust.pageElements[$route.path.split('/')[1]] === undefined ? [] : $store.state.menust.pageElements[$route.path.split('/')[1]]" />
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
}
</script>
<!-- ToDo Burası Menüler -->
<style lang="scss">
@import "@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
